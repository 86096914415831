
import axios from 'axios';

import SidebarLayout from './layouts/SidebarLayout.vue'
import SimpleLayout from './layouts/SimpleLayout.vue'

axios.defaults.baseURL = 'https://ff-dash.oroojo.com/api/v1';
axios.defaults.headers.common["Authorization"] = `Bearer ${window.localStorage.getItem('access_token')}`;
axios.interceptors.response.use(response => {
  return response
}, error => {
  if(error.response.status === 401 || error.response.status === 422) {
    window.localStorage.clear();
    window.location.href = '/login';
  }
  return Promise.reject(error)
});

export default {
  components: {
    'sidebar-layout': SidebarLayout,
    'simple-layout': SimpleLayout
    // define as many layouts you want for the application
  },
}
