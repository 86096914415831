import { createStore } from "vuex";
import AuthModule from "./module/auth";

const store = createStore({
  modules: {
    auth: AuthModule,
  },
});

export default store;
