import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/login",
    meta: { layout: "simple-layout", isAuthPage: true },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/registration",
    meta: { layout: "simple-layout", isAuthPage: true },
    component: () => import("../views/Registration.vue"),
  },
  {
    path: "/reset-password",
    meta: { layout: "simple-layout", isAuthPage: true },
    component: () => import("../views/ResetPasswrod.vue"),
  },
  {
    path: "/profile",
    component: () => import("../views/Profile.vue"),
  },
  // {
  //   path: "/glossary",
  //   component: () => import("../views/Glossary.vue"),
  // },
  {
    path: "/priorities",
    component: () => import("../views/Priorities.vue"),
  },
  {
    path: "/performance",
    component: () => import("../views/Performance.vue"),
  },
  {
    path: "/scenarios",
    component: () => import("../views/Scenarios.vue"),
  },
  {
    path: "/survey",
    component: () => import("../views/Survey.vue"),
  },
  {
    path: "/surveys",
    redirect: "/surveys/environmental",
  },
  {
    path: "/surveys/environmental",
    component: () => import("../views/EnvForm.vue"),
  },
  {
    path: "/surveys/economic",
    component: () => import("../views/EconForm.vue"),
  },
  {
    path: "/surveys/social",
    component: () => import("../views/SocialForm.vue"),
  },
  {
    path: "/admin/farms",
    component: () => import("../views/AdminFarms.vue"),
  },
  {
    path: "/admin/globalstats",
    component: () => import("../views/AdminGlobalStats.vue"),
  },
  {
    path: "/404",
    meta: { layout: "simple-layout" },
    component: () => import("../views/404NotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = window.localStorage.getItem("access_token");
  if (!to.meta.isAuthPage && !isAuthenticated) next({ path: '/login' })
  else if (to.meta.isAuthPage && isAuthenticated) next({ path: '/' })
  else next()
});

export default router;
