const state = () => ({
    authData: {
      token: "",
      refreshToken: "",
      tokenExp: "",
      userId: "",
      userName: "",
    },
  });
   
  const getters = {};
   
  const actions = {};
   
  const mutations = {};
   
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };